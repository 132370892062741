<template>
  <div>
    <div class="compont_p">
      订单详情
      <div class="btn flex_c_c" @click="backOrder">返回上级</div>
    </div>
    <div class="info_box mt_10">
      <div class="c3 flex_row_aic">
        <p class="mr_40"><span class="c6">订单编号：</span>{{ detail.orderno }}</p>
        <p class="mr_40"><span class="c6">订单时间：</span>{{ detail.order_time }}</p>
        <p class="mr_40"><span class="c6">状态：</span>{{ detail.status_str }}</p>
        <!-- <el-button class="custombtn" type="primary">付款</el-button> -->
      </div>
      <!-- <p class="c3"><span class="c6">销售单位：</span>{{ detail.dwmc }}</p> -->
      <p class="c3"><span class="c6">配送方式：</span>{{ detail.address && detail.address.psfs_str }}</p>
      <p class="c3"><span class="c6" v-if="psfs.self_address">自提地址：</span>{{ psfs.self_address }}</p>
      <p class="c3"><span class="c6">支付方式：</span>{{ detail.fkfs_str }}</p>
      <p class="c3">
        <span class="c6">收货信息：</span>{{ detail.address && detail.address.shr }}&nbsp;&nbsp; {{ detail.address && detail.address.shdh }}&nbsp;&nbsp;&nbsp;
        {{ detail.address && detail.address.area_str }}{{ detail.address && detail.address.shdz }}
      </p>
      <p class="c3"><span class="c6">用户备注：</span>{{ detail.notes }}</p>
    </div>
    <el-table :data="detail.goods" class="mt_10" :row-class-name="tableRowClassName">
      <el-table-column prop="date" label="商品名称/规格" align="center" width="150">
        <template slot-scope="{ row }">
          <p>{{ row.title }}</p>
          <p class="c6">{{ row.specification }}</p>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="yxq" label="有效期" align="center" /> -->

      <el-table-column prop="manufacturer" label="厂家" align="center" width="200" />
      <el-table-column prop="price" label="购买价格" align="center" />
      <el-table-column prop="number" label="商品数量" align="center" />
      <!-- <el-table-column prop="outstock_number" label="出库数量" align="center" /> -->
      <el-table-column prop="outstock_number" label="出库数量" align="center" width="150">
        <template slot-scope="{ row }">
          <p>{{ row.outstock_number ? row.outstock_number : 0 }}</p>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="pihao" label="批号" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.pihao ? row.pihao : "--" }}</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="yxq" label="有效期" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.yxq ? row.yxq : "--" }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="pihao" label="批号" align="center" />
      <el-table-column prop="yxq" label="有效期" align="center" />
      <el-table-column prop="total_price" label="购买合计" align="center" />
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button v-if="row.splx == 1 || row.splx == 2" class="custombtn" type="primary" @click="handleBuy(row)">再次购买</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="amount">
      <div>
        <p>
          商品金额：<span>{{ detail.goods_amount }}</span>
        </p>
        <p v-if="detail.outstock_amount">
          出库金额：<span>{{ detail.outstock_amount }}</span>
        </p>
        <p>
          订单优惠总额：<span class="color_theme">{{ detail.amount_yh }}</span>
        </p>
        <!-- <p>
          优惠券金额：<span class="color_theme">{{ detail.amount_yh }}</span>
        </p> -->
        <p>
          商品运费：<span>{{ detail.amount_yf }}</span>
        </p>

        <p>
          实付金额：<span>{{ detail.total_price }}</span>
        </p>
      </div>
    </div>
    <!-- <div class="note">
      <p>
        用户备注：<span>{{ detail.notes }}</span>
      </p>
      <p>
        优惠信息：<span>{{ detail.yhxx }}</span>
      </p>
    </div> -->
    <div class="handle-box">
      <el-button v-if="detail.status == 4 || detail.status == 3" type="primary" @click="downOrder">下载订单</el-button>
      <el-button v-if="detail.btn_rebuy == 101" type="primary" @click="buyTwices">再次购买</el-button>
      <el-button v-if="detail.btn_aftersale == 101" type="primary" @click="applayAfterSale">申请售后</el-button>
      <el-button v-if="detail.btn_cancle == 101" type="info" @click="cancleOrder">取消订单</el-button>
      <el-button v-if="detail.btn_logistics == 101" type="primary" @click="readLogistic">查看物流</el-button>
      <el-button v-if="detail.btn_pay == 101" type="success" @click="payNow">立即支付</el-button>
      <el-button v-if="detail.btn_receipt == 101" type="primary" @click="confirmReceipt">确认收货</el-button>
    </div>
  </div>
</template>

<script>
import { saveFile, formatAmount } from "@/utils";
// import { formatAmount } from "@/utils";
export default {
  data() {
    return {
      detail: {},
      id: "",
      orderno: "",
      deliverymethod: [],
      psfs: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.orderno = this.$route.query.orderno;
    this.getData();
    this.getDeliverymethod();
    // console.log("订单详情---", id, orderno);
  },
  methods: {
    formatAmount,
    getData() {
      this.$api("account.getOrderDel", { id: this.id, orderno: this.orderno }).then((res) => {
        this.detail = res.data;
      });
    },
    getDeliverymethod() {
      let p = {
        ddbh: this.$route.query.orderno,
      };
      this.$api("cart.getDeliverymethod", p).then((res) => {
        this.deliverymethod = res.data.list;
        // let ind = this.deliverymethod.findIndex((item) =>)
        let index = this.deliverymethod.findIndex((item) => item.title == this.detail.address.psfs_str);
        console.log("配送方式-----", this.deliverymethod[index]);
        this.psfs = this.deliverymethod[index];
      });
    },
    handleBuy(item) {
      let p = {
        id: item.id,
        number: item.number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("已加入购物车");
        this.$store.dispatch("getBadge");
      });
    },
    tableRowClassName({ row }) {
      if (row.done == 2) {
        return "warning-row";
      }
      return "";
    },
    backOrder() {
      // console.log("订单状态----", this.detail.status);
      this.$router.push({
        path: "/index/subRouter/order",
        query: { type: 101, current: this.detail.status },
      });
    },
    // 下载订单
    downOrder() {
      this.$api("account.orderDown", { id: this.id }).then((res) => {
        console.log("下载订单----", res);
        saveFile(res.data.title, res.data.key, res.data.list, res.data.file_name);
      });
    },
    // 再次购买
    buyTwices() {
      this.$api("account.buyTwice", { id: this.detail.id, orderno: this.detail.orderno }).then((res) => {
        if (res.code == 200) {
          this.$message.success("已加入购物车");
          this.$store.dispatch("getBadge");
        }
      });
    },
    // 申请售后
    applayAfterSale() {
      this.$router.push({
        path: "/index/subRouter/applyAfterSale",
        query: { orderno: this.detail.orderno, id: this.detail.id },
      });
    },
    // 取消订单
    cancleOrder() {
      this.$confirm("确定取消订单吗？", "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api("account.cancelOrder", { orderno: this.detail.orderno, id: this.detail.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("取消成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    // 查看物流
    readLogistic() {
      this.$router.push({
        path: "/index/subRouter/logistics",
        query: { orderno: this.detail.orderno, id: this.detail.id, type: "DD" },
      });
    },
    // 打开支付列表
    payNow() {
      // this.payInfo = data;
      // this.getPayData(data.orderno);
      // this.type = 102;
      // 跳转到新支付页面
      this.$router.push({
        path: "/index/payOrder",
        query: { type: 102, ddbh: this.detail.orderno },
      });
    },
    // 确认收货
    confirmReceipt() {
      this.$confirm("确认收货吗？", "确认收货", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api("account.deliveryOrder", { orderno: this.detail.orderno, id: this.detail.id }).then(() => {
            this.$message.success("收货成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 100px;
    top: 0;
    width: 76px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    background: @themeColor;
  }
}
.info_box {
  line-height: 3;
  .mr_40 {
    margin-right: 40px;
  }
  .custombtn {
    font-size: 14px;
    width: 76px;
  }
}
.amount {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 20px;
  font-weight: bold;
  line-height: 2.2;
  color: #333;
  span {
    color: #f93232;
  }
  .color_theme {
    color: @themeColor;
  }
}
.note {
  font-weight: bold;
  line-height: 2.2;
  color: #333;
}
.handle-box {
  width: 100%;
  // height: 80px;
  // background-color: cadetblue;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .handle-btn {
    width: 76px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    background-color: bisque;
  }
}
/deep/.el-table .warning-row {
  // background: rgb(103, 156, 247);
  background-color: rgb(247, 247, 248) !important;
}
</style>
